<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'dynamicQrCode', params: {} }"
          active
        >
          Dynamic Qr Code
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-md-row">
        <v-text-field
          dense
          v-model="parameters.searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
          hint="You may use + or - in front of each word to include and exclude keywords. Quotes to match exact phase."
        />
        <div
          class="d-flex flex-column flex-md-row ml-0 ml-md-4 mb-2 mb-lg-0 flex-shrink-0"
        >
          <v-text-field
            class="ml-0 ml-lg-3 flex-shrink-0"
            dense
            outlined
            clearable
            v-model="parameters.qrId"
            label="ID"
            @keyup.enter="search"
            @click:append="search"
            @click:clear="clearSearch"
            append-icon="mdi-magnify"
          />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-0 ml-md-2" color="primary" v-bind="attrs" v-on="on">
                Create QR Code
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'worksheetQRCodeForm' }">
                <v-list-item-title>Worksheet</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="dynamicQrCodeList"
          :items-per-page="pagination.pageSize"
          :loading="!dynamicQrCodeProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-pagination="
            !dynamicQrCodeList || dynamicQrCodeList.length < 1
          "
          :hide-default-header="
            !dynamicQrCodeList || dynamicQrCodeList.length < 1
          "
          :hide-default-footer="
            !dynamicQrCodeList || dynamicQrCodeList.length < 1
          "
          item-key="id"
          class="elevation-1"
          :options.sync="options"
          @update:options="pageChanged"
        >
          <template v-slot:item.id="{ item }">
            <div class="text-center">
              <v-btn icon @click="viewQrCode(item.uuid)">
                <v-icon>mdi-qrcode</v-icon>
              </v-btn>
              <div class="text-caption">{{ item.id }}</div>
            </div>
          </template>

          <template v-slot:item.staffId="{ item }">
            <a @click="view(item)">{{ item.staffName }}</a>
            <div class="text-caption">{{ item.createTime | formatDateYear }}</div>
          </template>

          <template v-slot:item.type="{ item }">
            <a @click="view(item)">
              <div>{{ item.businessType }}</div>
            </a>
          </template>

          <template v-slot:item.searchText="{ item }">
            <div class="my-2" style="white-space: pre">{{ item.searchText }}</div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mt-3 ml-3" v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-2">
                <v-list-item @click="edit(item)">
                  <v-list-item-icon><v-icon > mdi-pencil-outline </v-icon></v-list-item-icon>
                  <v-list-item-content> {{ $vuetify.lang.t("$vuetify.base.edit") }} </v-list-item-content>
                </v-list-item>
                <v-list-item @click="viewWorksheets(item)">
                  <v-list-item-icon><v-icon > mdi-card-search-outline </v-icon></v-list-item-icon>
                  <v-list-item-content>
                    {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="remove(item)">
                  <v-list-item-icon><v-icon > mdi-delete-outline </v-icon></v-list-item-icon>
                  <v-list-item-content>
                    {{ $vuetify.lang.t("$vuetify.base.delete") }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />


    <v-dialog style="z-index: 1000" v-model="worksheetsDialog">
      <v-card v-if="worksheetsDialog">
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="worksheetsDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="d-flex">
          <qr-worksheets :qrCodeId="selectedQrCode.id"/>
        </v-card-text>
      </v-card>
      <div v-else class="text-subtitle-2">
        {{ $vuetify.lang.t("$vuetify.label.foundX", 0 ) }}
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const QrWorksheets = () =>
  import("@/components/processWorksheet/QrWorksheets.vue");
import snakeCase from "lodash.snakecase";

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      dynamicQrCodeList: [],
      showParentPage: true,
      selectedQrCode: null,
      worksheetsDialog: false,
      options: {
        sortBy: ["create_time"],
        sortDesc: ["true"],
      },
      headers: [
        {
          text: "Code",
          value: "id",
          align: "start",
          sortable: true,
        },
        {
          text: "Staff",
          value: "staffId",
          align: "start",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          align: "start",
          sortable: true,
        },
        {
          text: "Data",
          value: "searchText",
          align: "start",
          sortable: false,
        },

        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      sort: {
        key: null,
      },
    };
  },
  components: {
    QrWorksheets,
  },
  computed: {
    ...mapGetters("dynamicQrCode", [
      "dynamicQrCodeProcessed",
      "getDynamicQrCodes",
    ]),
    parameters: {
      get() {
        return this.$store.state.dynamicQrSearchParameters.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("dynamicQrCode", [
      "deleteDynamicQrCode",
      "fetchDynamicQrCodes",
    ]),
    ...mapActions("dynamicQrSearchParameters", ["updateParameters"]),
    viewQrCode(uuid) {
      this.$router.push({ name: "worksheetQRCodePrint", params: { uuid } });
    },
    view(item) {
      this.$router.push({
        name: "dynamicQrCodeView",
        params: { id: item.uuid },
      });
    },
    viewWorksheets(item){
      this.worksheetsDialog = true;
      this.selectedQrCode = item;
    },
    edit(item) {
      if (item.businessType == "WORKSHEET") {
        this.$router.push({
          name: "worksheetQRCodeForm",
          params: { uuid: item.uuid },
        });
      } else {
        this.$router.push({
          name: "dynamicQrCodeEdit",
          params: { id: item.uuid },
        });
      }
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this dynamicQrCode?"
      );
      if (confirm) {
        this.deleteDynamicQrCode(item.id)
          .then(() => {
            this.addSuccessMessage("dynamicQrCode was deleted successfully");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      let sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? snakeCase(this.options.sortBy[0])
          : "create_time";
      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;

      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        searchText: this.parameters.searchTerms,
        id: this.parameters.qrId,
      };
      this.fetchDynamicQrCodes(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            this.dynamicQrCodeList = response.content;
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;

      this.getResources();
    },
    search() {
      this.getResources();
    },
    clearSearch() {
      this.parameters.searchTerms = "";
      this.parameters.qrId = null;
      this.getResources();
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>
