var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index container"},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{on:{"showParent":_vm.showParent}})],1),(_vm.showParentPage)?_c('div',[_c('v-breadcrumbs',[_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c('v-breadcrumbs-divider',[_vm._v("/")]),_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'dynamicQrCode', params: {} },"active":""}},[_vm._v(" Dynamic Qr Code ")])],1),_c('div',{staticClass:"d-flex flex-column flex-md-row"},[_c('v-text-field',{attrs:{"dense":"","placeholder":"Press enter to begin search","append-icon":"mdi-magnify","outlined":"","clearable":"","label":"Search","hint":"You may use + or - in front of each word to include and exclude keywords. Quotes to match exact phase."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click:clear":_vm.clearSearch,"click:append":_vm.search},model:{value:(_vm.parameters.searchTerms),callback:function ($$v) {_vm.$set(_vm.parameters, "searchTerms", $$v)},expression:"parameters.searchTerms"}}),_c('div',{staticClass:"d-flex flex-column flex-md-row ml-0 ml-md-4 mb-2 mb-lg-0 flex-shrink-0"},[_c('v-text-field',{staticClass:"ml-0 ml-lg-3 flex-shrink-0",attrs:{"dense":"","outlined":"","clearable":"","label":"ID","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click:append":_vm.search,"click:clear":_vm.clearSearch},model:{value:(_vm.parameters.qrId),callback:function ($$v) {_vm.$set(_vm.parameters, "qrId", $$v)},expression:"parameters.qrId"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0 ml-md-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Create QR Code ")])]}}],null,false,545273380)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'worksheetQRCodeForm' }}},[_c('v-list-item-title',[_vm._v("Worksheet")])],1)],1)],1)],1)],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dynamicQrCodeList,"items-per-page":_vm.pagination.pageSize,"loading":!_vm.dynamicQrCodeProcessed,"server-items-length":_vm.pagination.totalSize,"page":_vm.pagination.current,"disable-pagination":!_vm.dynamicQrCodeList || _vm.dynamicQrCodeList.length < 1,"hide-default-header":!_vm.dynamicQrCodeList || _vm.dynamicQrCodeList.length < 1,"hide-default-footer":!_vm.dynamicQrCodeList || _vm.dynamicQrCodeList.length < 1,"item-key":"id","options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.pageChanged]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewQrCode(item.uuid)}}},[_c('v-icon',[_vm._v("mdi-qrcode")])],1),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.id))])],1)]}},{key:"item.staffId",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view(item)}}},[_vm._v(_vm._s(item.staffName))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("formatDateYear")(item.createTime)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view(item)}}},[_c('div',[_vm._v(_vm._s(item.businessType))])])]}},{key:"item.searchText",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2",staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(item.searchText))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 ml-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-2"},[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.base.edit"))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.viewWorksheets(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-card-search-outline ")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.worksheet.worksheet"))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.base.delete"))+" ")])],1)],1)],1)]}}],null,false,3354684992)})],1)],1):_vm._e(),_c('confirmation-dialog',{ref:"confirm"}),_c('v-dialog',{staticStyle:{"z-index":"1000"},model:{value:(_vm.worksheetsDialog),callback:function ($$v) {_vm.worksheetsDialog=$$v},expression:"worksheetsDialog"}},[(_vm.worksheetsDialog)?_c('v-card',[_c('v-card-title',{staticClass:"justify-end"},[_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.worksheetsDialog = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',{staticClass:"d-flex"},[_c('qr-worksheets',{attrs:{"qrCodeId":_vm.selectedQrCode.id}})],1)],1):_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.label.foundX", 0 ))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }